<!--
 * @Author: zxf
 * @Date: 2023-08-07 10:34:07
 * @LastEditors: zxf
 * @LastEditTime: 2023-09-08 11:03:56
 * @Description: file content
-->
<template>
  <div style=" width: 100%;height: 100%;">
    <a-scene
      embedded
      vr-mode-ui="enabled: false"
      inspector="url: /3DModel/js/aframe-inspector.min.js;"
      renderer="physicallyCorrectLights: true;colorManagement: true;"
      style=" width: 100%;height: 100%;"
    >
      <!-- loading-screen="enabled:false" -->
      <a-assets>
        <img
          id="sky"
          src="/3DModel/npl/ppt/shanghai_riverside.jpg"
        >
        <img
          id="floor-jpg"
          src="/3DModel/npl/ppt/floor.jpg"
        >
        <a-asset-item
          id="office-gltf"
          src="/3DModel/npl/model/office.gltf"
        />
        <img
          id="screen_ppt-jpg"
          src="/3DModel/npl/ppt/screen_ppt.png"
        >
        <img
          id="arrow-png"
          src="/3DModel/npl/ppt/arrow.png"
        >
        <a-asset-item
          id="table-gltf"
          src="/3DModel/npl/model/table.gltf"
        />
        <img
          id="computer_ppt-png"
          src="/3DModel/npl/ppt/computer_ppt.png"
        >
        <img
          id="desktop_ppt-png"
          src="/3DModel/npl/ppt/desktop_ppt.png"
        >
        <img
          id="tv_ppt-png"
          src="/3DModel/npl/ppt/tv_ppt.png"
        >
        <a-asset-item
          id="computer-gltf"
          src="/3DModel/npl/model/computer.gltf"
        />
        <a-asset-item
          id="desktop-gltf"
          src="/3DModel/npl/model/desktop.gltf"
        />
        <a-asset-item
          id="tv-gltf"
          src="/3DModel/npl/model/screen.gltf"
        />
        <a-asset-item
          id="officeWoman-gltf"
          src="/3DModel/npl/model/officeWoman.gltf"
        />
        <a-asset-item
          id="camera-gltf"
          src="/3DModel/npl/model/camera.gltf"
        />
        <a-asset-item
          id="table2-gltf"
          src="/3DModel/npl/model/table2.gltf"
        />
      </a-assets>

      <a-camera
        id="camera"
        fov="60"
        near="0.001"
        far="10000"
        look-controls="enabled: true; pointerLockEnabled: false;"
        position="-0.178 1.520 1.745"
        rotation="0 0 0 "
      >
        <!-- 环境光 -->
        <a-light
          id="ambientLight"
          type="ambient"
          color="#FFFFFF"
          intensity="0.3"
        />
        <!-- 平行光 -->
        <a-light
          id="dircetLight"
          type="directional"
          color="#CCDDFF"
          intensity="0.3"
          target="#directionaltarget"
        >
          <a-entity
            id="directionaltarget"
            position="0 0 -1"
          />
        </a-light>
      </a-camera>

      <a-sky
        src="#sky"
      />

      <!-- 办公室 -->
      <a-gltf-model
        id="office"
        src="#office-gltf"
        scale="1 1 1"
        position="0 -0.1 4"
        rotation="0 0 0"
      >
        <!-- 地板 -->
        <a-entity
          id="floor"
          geometry="primitive: plane; width: 35; height: 14.5;"
          material="src: #floor-jpg; repeat: 35 10; roughness: 0; metalness:0;"
          rotation="-90 0 0"
          position="0 0.11 -0.6"
        />

        <!-- 桌子 -->
        <a-gltf-model
          id="table2"
          src="#table2-gltf"
          scale="2.5 2.2 3"
          position="0 0 -5.283"
          rotation="0 110 0"
        />

        <!-- 布光 -->
        <a-entity id="lights">
          <a-entity
            area-light="width: 35; height: 4; color: #ccddff; helperColor: #ccddff; showHelper: false;"
            rotation="0 0 0"
            position="-17.573 2.135 -7.52"
          />
          <a-entity
            area-light="width: 35; height: 4; color: #ccddff; helperColor: #ccddff; showHelper: false;"
            rotation="0 180 0"
            position="17.524 2.135 6.579"
          />
          <a-entity
            area-light="width: 14.5; height: 4; color: #ccddff; helperColor: #ccddff; showHelper: false;"
            rotation="0 90 0"
            position="-17.269 2.135 6.675"
          />
          <a-entity
            area-light="width: 14.5; height: 4; color: #ccddff; helperColor: #ccddff; showHelper: false;"
            rotation="0 270 0"
            position="17.269 2.135 -7.549"
          />
          <a-entity
            area-light="width: 35; height: 14.5; color: #ccddff; helperColor: #ccddff; showHelper: false;"
            rotation="90 0 0"
            position="-17.592 4.155 -0.595"
          />
          <a-entity
            area-light="width: 35; height: 14.5; intensity: 1.5; color: #ccddff; helperColor: #ccddff; showHelper: false;"
            rotation="270 0 0"
            position="-17.428 0.112 -0.546"
          />
        </a-entity>

        <!-- 投屏 -->
        <a-plane
          id="tvScreen"
          src="#screen_ppt-jpg"
          height="3.5"
          width="6"
          rotation="0 0 0"
          position="0, 2.202, -7.57"
          scale="1 1 1"
          material="repeat: 1 0.25; offset: 0 0.75"
          animation__open1="property: material.offset; to: 0 0.5; dur: 1000; startEvents: open1; autoplay: false;"
          animation__open2="property: material.offset; to: 0 -0.001; dur: 1; startEvents: open2; autoplay: false;"
        >
          <a-plane
            id="arrow1"
            src="#arrow-png"
            height="0.15"
            width="0.4"
            material="alphaTest: 0.02"
            rotation="0 0 0"
            position="1.849 1.132 0.032"
            animation="property: material.offset; from: -1 0; to: 0 0; dur: 1500; loop:true;"
            visible="false"
          />
          <a-plane
            id="arrow2"
            src="#arrow-png"
            height="0.15"
            width="0.4"
            material="alphaTest: 0.02"
            rotation="0 0 0"
            position="1.849 0.014 0.032"
            visible="false"
          />
          <a-plane
            id="arrow3"
            src="#arrow-png"
            height="0.15"
            width="0.4"
            material="alphaTest: 0.02"
            rotation="0 0 0"
            position="1.849 -1.142 0.032"
            visible="false"
          />
          <a-plane
            id="arrow4"
            src="#arrow-png"
            height="0.15"
            width="0.4"
            material="alphaTest: 0.02"
            rotation="0 0 0"
            position="0.915 1.132 0.032"
            visible="false"
          />
          <a-plane
            id="arrow5"
            src="#arrow-png"
            height="0.15"
            width="0.4"
            material="alphaTest: 0.02"
            rotation="0 0 0"
            position="0.915 0.014 0.032"
            visible="false"
          />
          <a-plane
            id="arrow6"
            src="#arrow-png"
            height="0.15"
            width="0.4"
            material="alphaTest: 0.02"
            rotation="0 0 0"
            position="0.915 -1.142 0.032"
            visible="false"
          />
        </a-plane>

        <a-gltf-model
          src="#camera-gltf"
          scale="2 2 2"
          position="3.5 3 -7.590"
          rotation="0 90 0"
        />
      </a-gltf-model>

      <!-- 电脑屏幕 -->
      <a-entity
        position="4.180, 0, -2.858"
      >
        <a-gltf-model
          id="table"
          src="#table-gltf"
          scale="1 1 1"
          position="0 0 0"
          rotation="0 60 0"
        >
          <a-gltf-model
            id="desktop"
            src="#desktop-gltf"
            scale="1 1 1"
            position="0 0.735 0"
            rotation="0 -60 0"
          />
        </a-gltf-model>

        <a-gltf-model
          id="computer"
          src="#computer-gltf"
          scale="1 1 1"
          position="-7.730 0 0"
          rotation="0 0 0"
        />

        <a-gltf-model
          id="tv"
          src="#tv-gltf"
          scale="1 1 1"
          position="-8.645 0 0"
          rotation="0 0 0"
        />

        <a-plane
          id="desktop_ppt"
          src="#desktop_ppt-png"
          height="0.4"
          width="0.6"
          rotation="-16.500 0 0"
          position="-0.015 1.016 -0.062"
          scale="1 1 1"
        />
        <a-plane
          id="computer_ppt"
          src="#computer_ppt-png"
          height="0.470"
          width="0.760"
          rotation="-56.000 0 0"
          position="-7.730 0.741 0.007"
          scale="1 1 1"
        />
        <a-plane
          id="tv_ppt"
          src="#tv_ppt-png"
          height="0.553"
          width="0.985"
          rotation="0 0 0"
          position="-8.645 1.091 0.029"
          scale="1 1 1"
        />
        <a-entity
          area-light="width: 8; height: 1; intensity: 3; color: #ccddff; helperColor: #ccddff; showHelper: false;"
          rotation="-130 0 0"
          position="-2.425 1.67408 -0.05957"
        />
        <a-entity
          area-light="width: 3; height: 3; intensity: 2; color: #ccddff; helperColor: #ccddff; showHelper: false;"
          rotation="-90 0 0"
          position="-10.183 2.372 1.593"
        />
      </a-entity>

      <a-gltf-model
        id="officeWoman"
        src="#officeWoman-gltf"
        scale="1 1 1"
        position="-2.503 0 -2"
        rotation="0 90 0"
        animation__reset1="property: rotation; easing: linear; to: 0 -90 0; dur: 200; startEvents: reset; autoplay: false;"
        animation__reset2="property: position; easing: linear; to: -2.503 0 -2; dur: 2000; startEvents: animationcomplete__reset1; autoplay: false;"
        animation__reset3="property: rotation; easing: linear; to: 0 90 0; dur: 200; startEvents: animationcomplete__reset2; autoplay: false;"
        animation__open1="property: position; easing: linear; to: 0 0 -2; dur: 3000; startEvents: open; autoplay: false;"
        animation__open2="property: rotation; easing: linear; to: 0 0 0; dur: 200; startEvents: animationcomplete__open1; autoplay: false;"
        animation__walk="property: rotation; easing: linear; to: 0 -90 0; dur: 200; startEvents: walk; autoplay: false;"
        animation__walk2="property: position; easing: linear; to: -2.503 0 -2; dur: 3000; startEvents: animationcomplete__walk; autoplay: false;"
        animation__walk3="property: rotation; easing: linear; to: 0 40 0; dur: 200; startEvents: animationcomplete__walk2; autoplay: false;"
      >
        <a-entity
          area-light="width: 2; height: 2; intensity: 2; color: #ccddff; helperColor: #ccddff; showHelper: false;"
          rotation="0 0 0"
          position="-0.397 2.026 0.9"
        />
      </a-gltf-model>
    </a-scene>

    <!-- 模型加载提示 -->
    <i
      :class="modelLoadIcon"
      style="position: fixed;top: 0;right: 0;z-index: 999;font-size: 1.5em;color: pink;vertical-align: middle;"
    />

    <!-- 开场gif -->
    <div
      v-if="ppt_begin"
      :class="ppt_begin"
    >
      <img
        v-show="ppt_begin_img"
        class="ppt_begin_img"
        src="/3DModel/npl/ppt/begin.gif"
      >
    </div>

    <!-- 字幕 -->
    <div
      v-show="word"
      class="ppt_word"
    >
      {{ word }}
    </div>

    <introuduce
      v-show="false"
      ref="introuduce"
    />
  </div>
</template>

<script>
import EventBus from '../../event-bus';
import introuduce from '../introuduce/index.vue';
import { nplService } from '../virtual/npl/npl.service';

function sleep (millisecond) {
  return new Promise((resolve) => {
    return setTimeout(() => {
      resolve(millisecond);
    }, millisecond);
  });
}

const clips = {
  walk: {
    clip: 'walk',
    loop: 'repeat',
    repetitions: Infinity,
    timeScale: 1.5
  },
  idle: {
    clip: 'idle',
    loop: 'pingpong',
    repetitions: Infinity,
    timeScale: 1
  },
  unknow: {
    clip: 'unknow',
    loop: 'repeat',
    repetitions: 1,
    timeScale: 0.2
  },
  Hello: {
    clip: 'Hello',
    loop: 'repeat',
    repetitions: 1,
    clampWhenFinished: true,
    timeScale: 0.7
  },
  HandUp: {
    clip: 'HandUp',
    loop: 'repeat',
    repetitions: 1,
    timeScale: 1
  },
  ShakeHead: {
    clip: 'ShakeHead',
    loop: 'repeat',
    repetitions: Infinity,
    timeScale: 1
  },
  RoundHand: {
    clip: 'RoundHand',
    loop: 'repeat',
    repetitions: 1,
    clampWhenFinished: true,
    timeScale: 1
  },
  Talk: {
    clip: 'Talk',
    loop: 'repeat',
    repetitions: 1,
    clampWhenFinished: true,
    timeScale: 1
  }
};

// const canvas = { height: 0, width: 0 };
// function resizeCanvas () {
//   const scene = document.querySelector('a-scene');
//   if (scene && (canvas.height !== scene.clientHeight || canvas.width !== scene.clientWidth)) {
//     scene.resize();
//     canvas.height = scene.clientHeight;
//     canvas.width = scene.clientWidth;
//   }
// }
// setInterval(() => {
//   resizeCanvas();
// }, 500);

function play (model, clip) {
  if (!model) return;
  model.setAttribute('animation-mixer', clip);
}

function afterPlay (model, clipName) {
  switch (clipName) {
    case 'HandUp':
      play(model, clips.RoundHand);
      break;
    case 'RoundHand':
      play(model, clips.Talk);
      break;
    case 'Talk':
      play(model, clips.HandUp);
      break;
    default:
      break;
  }
}

let _this = null;
const run = async (actions, i) => {
  const aAction = actions[i];
  aAction.action && await aAction.action();
  // 显示字幕
  _this.word = aAction.word;
  // 播放语音
  nplService.play(aAction.word, async () => {
    _this.word = null; // 隐藏字幕
    aAction.afterword && await aAction.afterword();
    if (i < actions.length - 1) {
      run(actions, i + 1); // 递归播放下个动作
    }
  });
};
const music = new Audio('/3DModel/npl/ppt/ppt.mp3');

export default {
  name: 'NplModel',
  components: {
    introuduce
  },
  data () {
    return {
      mainApp: null, // 语音唤醒API
      officeWoman: null,
      dircetLight: null,
      ppt_begin: 'ppt_begin',
      ppt_begin_img: false,
      word: null,
      modelLoadIcon: 'el-icon-loading'
    };
  },
  methods: {
    resetScene () {
      this.officeWoman.emit('reset');
      play(this.officeWoman, clips.idle);
      this.ppt_begin = 'ppt_begin';
    },
    isReady () {
      return this.officeWoman !== null;
    },
    run () {
      if (!this.isReady()) {
        this.$message('正在加载模型，请稍后再试');
        return;
      }

      const actions = [
        {
          word: '城市消防远程监控系统是一套基于互联网、物联网、大数据等技术的智能化消防监控系统。',
          action: async () => {
            console.log('屏幕A渐亮，同时一道横向电流脉冲划过屏幕，显示出科技感，介绍的数字人小莲缓缓显示出来，同时背景音乐起来');
            // 开场gif图
            _this.ppt_begin_img = true;
            music.play();
            await sleep(7000);
            music.pause();
            // 逐渐透明，显示场景
            _this.ppt_begin += ' ppt_begin__start';
            this.officeWoman.emit('open');
            play(this.officeWoman, clips.walk);
            await sleep(3600);
            _this.ppt_begin = null;
            _this.ppt_begin_img = false;
            play(this.officeWoman, clips.HandUp);
          }
        },
        {
          word: '整个系统由数据传输装置、数字值班机器人、消防数字平台、消防监控大屏、手机移动端等五部分组成',
          action: async () => {
            console.log('数字人小莲走向屏幕边缘，屏幕A上显示出整个系统的网络拓扑图，该图随着数字人的介绍，相应的模块逐一亮起');
            document.querySelector('#tvScreen').emit('open1');
            this.officeWoman.emit('walk');
            play(this.officeWoman, clips.walk);
            await sleep(3800);
            play(this.officeWoman, clips.HandUp);
          }
        },
        {
          word: '数据传输装置负责采集各消防设施的运行状态、报警信息、操作事件等数据',
          action: async () => {
            console.log('拓扑图中显示数据流向，从消防设施流向数据传输装置');
            for (let i = 1; i <= 3; i++) {
              document.querySelector('#arrow' + i).setAttribute('visible', true);
            }
          }
        },
        {
          word: '并将数据发送给消防数字值班机器人',
          action: async () => {
            console.log('数据流向增加从数据传输装置到数字值班机器人的数据流向');
            for (let i = 4; i <= 6; i++) {
              document.querySelector('#arrow' + i).setAttribute('visible', true);
            }
          }
        },
        {
          word: '数字值班机器人位于各消防联网单位的消控室内，承担消控室内部分值班人员的工作职能，实现消控室的数字化'
        },
        {
          word: '同时，数字值班机器人将消防设施的相关数据发送至消防数字平台。消防数字平台在云端对所有消防联网单位进行监控管理。',
          action: async () => {
            console.log('数据流向路径上增加从数字值班机器人到消防数字平台的流向');
            document.querySelector('#tvScreen').emit('open2');
          },
          afterword: async () => {
            console.log('屏幕A上逐渐高亮数字值班机器人模块');
            play(this.officeWoman, clips.idle);
            if (_this.mainApp) {
              // 重启启动语音唤醒
              _this.mainApp.startWait4WakenUp();
            }
            this.$refs.introuduce.fszl2Jqr();
          }
        }
      ];
      run(actions, 0);
    },
    run2 () {
      if (!this.isReady()) {
        this.$message('正在加载模型，请稍后再试');
        return;
      }
      const actions = [
        {
          word: '现在请大家跟随我回到中央位置，接下来为大家介绍消防监控大屏系统。大屏以全局的视角向使用者展现所有联网消防设施的运行状态。',
          action: async () => {
            play(this.officeWoman, clips.HandUp);
          },
          afterword: async () => {
            console.log('“屏幕C上数字人小莲走向左侧并消失，屏幕A出现数字人，并且切换到消防监控大屏的监控总览页面');
            play(_this.officeWoman, clips.walk);
            _this.officeWoman.emit('reset');
            if (_this.mainApp) {
              // 重启启动语音唤醒
              _this.mainApp.startWait4WakenUp();
            }
            await sleep(3000);
            _this.$router.push({ path: '/monitor', query: { introuduce: 'true' } });
          }
        }
      ];
      run(actions, 0);
    }
  },
  beforeMount () {
    // console.log('beforeMount');
    _this = this;
    document.addEventListener('model-loaded', event => {
      const el = event.target;
      if (el.id === 'officeWoman') {
        _this.modelLoadIcon = 'el-icon-check';
        _this.officeWoman = document.querySelector('#officeWoman');
        _this.dircetLight = document.querySelector('#dircetLight');
        // console.log('model-loaded');
        _this.resetScene();
      }
    });
    document.addEventListener('model-error', event => {
      _this.modelLoadIcon = 'el-icon-close';
    });
    document.addEventListener('animation-loop', event => {
      // console.log('animation-loop', event);
      afterPlay(_this.officeWoman, event.detail.action.getClip().name);
    });
    document.addEventListener('animation-finished', event => {
      // console.log('animation-finished', event);
      afterPlay(_this.officeWoman, event.detail.action.getClip().name);
    });
    document.addEventListener('keypress', (event) => {
      if (event.code === 'Digit0') {
        // 数字键0
        _this.resetScene();
      }

      if (event.code === 'Digit1') {
        // 数字键1
        _this.run();
      }

      if (event.code === 'Digit2') {
        // 数字键1
        // _this.run2();
        _this.$refs.introuduce.fszl('dp', 'start');
      }
    });
  },
  mounted () {
    console.log('mounted');

    // 监听EventBus触发事件
    /* 调用方式：
      1、 EventBus.$emit('nplListen', params);
      2、window.EventBus.$emit('nplListen', params);
    */
    EventBus.$on('nplListen', param => {
      console.log('EventBus事件触发，入参：', param);
      this.run();
    });

    // 监听演示指令
    EventBus.$on('reviceIntroduce', param => {
      console.log('EventBus事件触发，入参：', param);
      this.run2();
    });

    // 加载语音唤醒API
    // eslint-disable-next-line no-new, no-undef
    new QWebChannel(qt.webChannelTransport, function (channel) {
      _this.mainApp = channel.objects.mainApp;
    });
  }

};
</script>

<style lang="less" scoped>
.ppt_begin{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10% 0;
  text-align: center;
  background-color: #030112;

  &&__start {
    opacity: 0;
    transition: 5s;
  }

  && .ppt_begin_img {
    animation-name: scaleDraw; /* 关键帧名称 */
    animation-duration: 1.5s; /* 动画所花费的时间 */
    animation-timing-function: linear; /* 动画的速度曲线 */
    animation-iteration-count: 1; /* 动画播放的次数 */
    @keyframes scaleDraw {
      /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
      0% {
        transform: scale(8); /* 开始为原始大小 */
      }

      50% {
        transform: scale(4);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}

.ppt_word {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  padding: 15px 250px;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 4px;
  color: #fefefe;
  text-align: center;
  background-color: #030112;
}
</style>
